import React from "react"
import { Helmet } from "react-helmet"
import { JsonLd } from "../components/JsonLd"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import LandingHeader from "../components/landingHeader/landingHeader"
import Directory from "../components/directory/directory"
import MissionVision from "../components/missionVision"
import LatestArticles from "../components/latestArticles"
import LatestResourceLinks from "../components/latestResourceLinks"

const RenderBody = ({ meta, socialCard }) => (
  <>
    <Helmet
      title="Home"
      titleTemplate={`Home | ${meta.title}`}
      meta={[
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `description`,
          content: meta.description,
        },
        {
          property: `og:url`,
          content: `${meta.siteUrl}`,
        },
        {
          property: `og:title`,
          content: `Home`,
        },
        {
          property: `og:description`,
          content: meta.description,
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: "twitter:creator",
          content: "@tivp_org",
        },
        {
          name: "twitter:site",
          content: "@tivp_org",
        },
        {
          name: "twitter:title",
          content: `Home | TIVP`,
        },
        {
          name: "twitter:description",
          content: `${meta.description}`,
        },
        {
          name: "twitter:image",
          content: `${meta.url}${socialCard.publicURL}`,
        },
        {
          property: "og:image",
          content: `${meta.url}${socialCard.publicURL}`,
        },
      ].concat(meta)}
    >
      <html lang="en" />
      <link href={meta.siteUrl} rel="canonical" />
      <JsonLd>
        {{
          "@context": "https://schema.org",
          "@type": "EducationalOrganization",
          name: "The Infinite Voice Project",
          legalName: "Infinite Voice Project",
          url: "https://theinfinitevoiceproject.org",
          sameAs: "https://twitter.com/tivp_org",
          nonProfitStatus: "501c3",
          contactPoint: {
            "@type": "ContactPoint",
            email: "info@theinfinitevoiceproject.org",
            contactType: "inquiries",
          },
          address: {
            "@type": "PostalAddress",
            addressLocality: "Rio Rancho",
            addressRegion: "NM",
          },
        }}
      </JsonLd>
    </Helmet>
    <React.Fragment>
      <LandingHeader />
      <MissionVision />
      {/*
      <Featured />
      */}
      <LatestResourceLinks />
      <LatestArticles />
    </React.Fragment>
  </>
)

const HomePage = ({ data }) => {
  //Required check for no data being returned
  const meta = data.site.siteMetadata
  const socialCard = data.file

  if (!meta) return null

  return (
    <Layout>
      <div>
        <RenderBody meta={meta} socialCard={socialCard} />
        <Directory />
      </div>
    </Layout>
  )
}

export default HomePage

RenderBody.propTypes = {
  meta: PropTypes.object.isRequired,
}

export const query = graphql`
  query Home {
    file(name: { eq: "defaultSocialCardImage" }) {
      publicURL
    }
    site {
      siteMetadata {
        title
        description
        author
        url
        siteUrl
      }
    }
  }
`
