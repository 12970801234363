import React from "react"
import MarqueeTemplate from "./marqueeTemplate"

export default function InfiniteStarConnectionsMarquee() {
  return (
    <MarqueeTemplate>
        <p className="text-white">
          Join Viviane & Jeff live this Sunday on{" "}
          <a
            href="https://www.youtube.com/channel/UCFJwX6NMbzTWRCmgV5Tc0lA"
            className="text-cyan-200"
            target="_blank"
            external="true"
            rel="noopener noreferrer nofollow"
          >
            The Infinite Star Connections
          </a>{" "}
          with Dr. Ray Keller - live at 11:00 am PST / 2:00 pm EST!
        </p>
    </MarqueeTemplate>
  )
}
