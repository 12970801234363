import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import PostCard from "./cards/postCard"

export default function LatestArticles() {
  const data = useStaticQuery(graphql`
  query HomeRecentPosts {
    allGraphCmsPost(limit: 4, skip: 1, sort: {fields: date, order: DESC}) {
      edges {
        node {
          id
          title
          slug
          description
          date
          category {
            title
            slug
          }
          featuredImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 50
                  width: 200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          author {
            name
            id
          }
        }
      }
    }
  }
  
  `)

  const posts = data.allGraphCmsPost.edges

  return (
    <div className="w-full flex justify-center bg-white snap-start">
      <div className="w-full desktop:w-4/5 4k:w-2/5 px-8 desktop:px-0 flex flex-col desktop:flex-row justify-center">
        <div className="w-full flex flex-row flex-wrap content-start py-24">
          <div className="w-full">
            <h1>Latest Articles</h1>
          </div>
          <div className="w-full grid phone:grid-cols-1 tablet:grid-cols-2 grid-cols-4 gap-12">
            {posts.map((post, i) => (
              <PostCard
                key={i}
                category={post.node.category.title}
                categorySlug={post.node.category.slug}
                title={post.node.title}
                date={post.node.date}
                description={post.node.description}
                id={post.node.id}
                slug={post.node.slug}
                image={post.node.featuredImage.localFile.childImageSharp.gatsbyImageData}
                author={post.node.author.name}
              />
            ))}
          </div>
          <div className="w-full flex justify-end">
            <button className="pt-8 font-oswald text-base desktop:text-2xl font-thin text-stone-700 hover:text-blue-500" aria-label="Read More Articles">
              <Link to="/articles">
                  More Articles <span>&#8594;</span>
              </Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
