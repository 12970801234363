import React from "react"
import { Link } from "gatsby"
import Button from "../components/buttons/button"
import TIVPlogo from "../assets/images/tivp-logo.png"

export default function MissionVision() {
  return (
    <div className="w-full flex justify-center py-24 bg-white">
      <div className="w-full flex phone:flex-col flex-row items-center desktop:w-4/5 4k:w-2/5">
        <div className="w-full flex flex-col items-center">
          <div className="w-full desktop:w-3/5 flex phone:flex-col flex-row justify-start phone:px-8">
            <div className="w-full desktop:w-1/3 flex items-center justify-center pr-0 desktop:pr-20">
              <img src={TIVPlogo} className="w-60 h-60 mb-8 desktop:mb-0"/>
            </div>
          <div className="w-full desktop:w-2/3 h-full">
            <h1>
              Our Mission
            </h1>
            <p className="text-slate-700 font-roboto mb-8">
              To educate people worldwide regarding our planet's Star Nation
              visitors, their many cultures, and their various reasons for being
              on Earth. To foster a right-minded approach of mutual respect,
              communication and cooperation with our world's Star Nations
              through education.
            </p>
            <Link to="/about-us">
            <Button>Learn More About Us</Button>
            </Link>
          </div>
        </div>
        </div>
      </div>
    </div>
  )
}
