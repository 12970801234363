import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"
import tw from "twin.macro"
import Marquee from "../marquees/theInfiniteStarConnections"
import BackgroundImage from "gatsby-background-image"

const LandingHeader = ({ className }) => {
  const data = useStaticQuery(graphql`
    query landingHeaderImages {
      bg: file(name: { eq: "Night_Sky_Landing" }) {
        name
        childImageSharp {
          fluid(toFormat: AUTO, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo: file(name: { eq: "tivp-logo-notext" }) {
        name
        childImageSharp {
          gatsbyImageData(quality: 50, width: 300, height:290, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `)

  const bg = data.bg.childImageSharp.fluid
  const logo = data.logo.childImageSharp.gatsbyImageData

  return (
    <div className="laptop:h-min-full flex flex-col justify-center">
      <BackgroundImage tab="div" className={className} fluid={bg}>
        <Marquee />
        <div className="h-full phone:h-screen desktop:h-auto tablet:h-auto w-full mx-auto flex justify-center items-center">
          <div className="relative desktop:max-w-2xl desktop:w-full flex justify-center">
            <div className="laptop:pt-16 mx-auto px-6 desktop:px-8 flex flex-col flex-wrap">
              <div className="w-full h-auto flex justify-center content-center items-center pt-0 desktop:pt-20">
                <GatsbyImage
                  image={logo}
                  className="w-full h-full"
                  alt="tivp logo"
                />
              </div>
              <div className="py-20 w-full text-center pt-6 flex items-center flex-col">
                <div className="text-6xl tracking-tight leading-10 font-extrabold text-white font-oswald phone:leading-none tablet:text-6xl">
                  The Infinite
                  <br />
                </div>
                <div className="text-6xl tracking-tight leading-10 font-extrabold text-white font-oswald phone:leading-none tablet:text-6xl pt-4">
                  Voice Project
                </div>
                <div className="text-3xl tracking-tight leading-10 font-hairline text-white font-oswald phone:leading-none pt-4">
                  501(c)3
                </div>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
    </div>
  )
}

const StyledLandingHeader = styled(LandingHeader)`
  background-repeat: none;
  background-position: center center;
  ${tw`bg-cover`}
`
export default StyledLandingHeader
