import React from "react"
import Marquee from "react-fast-marquee"
import PropTypes from "prop-types"

export default function MarqueeTemplate({ children }) {
  return (
    <Marquee
      pauseOnHover={true}
      speed={40}
      gradient={false}
      className="bg-gray-900/60 z-10"
    >
      <div className="h-12 flex items-center">{children}</div>
    </Marquee>
  )
}

MarqueeTemplate.propTypes = {
  children: PropTypes.node.isRequired,
}
