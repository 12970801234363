import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import ResourceCard from "../components/cards/resourceCard"


export default function LatestResourceLinks() {
  const data = useStaticQuery(graphql`
  query HomeRecentResourceLinks {
    allGraphCmsResource(limit: 4, sort: {order: DESC, fields: date}) {
      edges {
        node {
          title
          id
          slug
          description
          date
          featuredImage {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 50, width: 200, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
        }
      }
    }
  }
  `)
  const resLinks = data.allGraphCmsResource.edges

  return (
    <div className="w-full flex justify-center py-24 phone:hidden tablet:hidden bg-stone-100">
      <div className="w-4/5 flex flex-col justify-center">
        <div className="w-full">
          <h1>Latest Resource Links</h1>
        </div>
        <div className="w-full flex justify-center">
          <div className="w-full grid grid-cols-4 gap-10">

              {resLinks.map((resLink, i) => (
                <ResourceCard
                  id={resLink.node.id}
                  key={i}
                  image={resLink.node.featuredImage.localFile.childImageSharp.gatsbyImageData}
                  title={resLink.node.title}
                  description={resLink.node.description}
                  slug={resLink.node.slug}
                />
              ))}

          </div>
        </div>
        <div className="w-full flex justify-end">
            <button className="pt-10 font-oswald text-base desktop:text-2xl font-thin text-stone-700 hover:text-blue-500" aria-label="view more resource links">
              <Link to="/resource-links">
                  More Resource Links <span>&#8594;</span>
              </Link>
            </button>
          </div>
      </div>
    </div>
  )
}
